import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {PrintService, UsbDriver} from 'ng-thermal-print';
import {CookieService} from 'ngx-cookie-service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {


  constructor(private printService: PrintService, private cookies: CookieService) {

  }
}
