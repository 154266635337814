import { Component, OnInit, ViewChild } from '@angular/core';
import { Reservations } from '../../Models/Reservations';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import { CommandesService } from '../../Services/Commandes/commandes.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmationBannerComponent } from '../confirmation-banner/confirmation-banner.component';

@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.css']
})
export class ReservationComponent implements OnInit {
  listeReservations: Reservations[] = [];
  listeReservations1: Reservations[] = [];

  displayedColumns: string[] = ['id','nom', 'tel','email', 'age', 'nombreHomme', 'nombreFemme', 'date','heure','status','action'];
  dataSource = new MatTableDataSource(this.listeReservations);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  constructor(private commandService: CommandesService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    // get all commandes
    this.commandService.getAllReservation().subscribe((data: Reservations[]) => {
      this.listeReservations = Object.assign(this.listeReservations, data);
      // affecter les commandes en cours au datasource.data
 
      for (const r of this.listeReservations) {
          this.listeReservations1.push(r);
        this.dataSource.data = this.listeReservations1;
      }
    });
  }


  cancel(res: Reservations) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = '15%';
    dialogConfig.height = '20%';
    const dialogref = this.dialog.open(ConfirmationBannerComponent, dialogConfig);
    dialogref.afterClosed().subscribe((retour: boolean) => {
      if (retour === true) {
        res.status = 2;
          this.commandService.cancelReservation(res).subscribe(data => {
          this.dataSource.paginator = this.paginator;
        });
      }
    });
  }

  confirm(res: Reservations) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = '15%';
    dialogConfig.height = '20%';
    const dialogref = this.dialog.open(ConfirmationBannerComponent, dialogConfig);
    dialogref.afterClosed().subscribe((retour: boolean) => {
      if (retour === true) {
        res.status = 1;
          this.commandService.confirmReservation(res).subscribe(data => {
          this.dataSource.paginator = this.paginator;
        });
      }
    });
  }


}
