export class Env {
  static url = 'https://apifresco.msdigital34.fr';
  static urlReservation='https://apifresco.msdigital34.fr'
  static firebase = {

    apiKey: "AIzaSyBg30w9BmQFP4S3ZnSCs2cB4txhBLGkjRg",
    authDomain: "naanpalace-17790.firebaseapp.com",
    projectId: "naanpalace-17790",
    storageBucket: "naanpalace-17790.appspot.com",
    messagingSenderId: "668223551739",
    appId: "1:668223551739:web:e1924b936a40412527185e"
  };
}
