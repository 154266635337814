import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Commande} from '../../Models/commande';
import {Plat} from '../../Models/plat';
import {PrintService, UsbDriver, WebPrintDriver} from 'ng-thermal-print';
import {Offre} from '../../Models/offre';
import {RestaurantInfoService} from '../../Services/RestaurantInfoService/restaurant-info.service';
import {RestaurantInfo} from '../../Models/restaurant-info';
import {CustomOffres} from '../../Models/custom-offres';
import {CommandesService} from '../../Services/Commandes/commandes.service';
import {toNumbers} from '@angular/compiler-cli/src/diagnostics/typescript_version';

@Component({
  selector: 'app-detaille-commande',
  templateUrl: './detaille-commande.component.html',
  styleUrls: ['./detaille-commande.component.css']
})
export class DetailleCommandeComponent implements OnInit {

  constructor(private matdialogref: MatDialogRef<DetailleCommandeComponent>,
              private rs: RestaurantInfoService, @Inject(MAT_DIALOG_DATA) public data: any,
              private printService: PrintService, private cs: CommandesService) {
    this.usbPrintDriver = new UsbDriver();
    this.printService.isConnected.subscribe(result => {
      this.status = result;
    });
  }

  restau: RestaurantInfo;
  listeplats: Plat[] = this.data.requested_plat;
  listeOffres: CustomOffres[] = this.data.custom_offres;
  listePlatsOffres: Plat[] = [];
  panelOpenState = true;
  status = false;
  usbPrintDriver: UsbDriver;
  webPrintDriver: WebPrintDriver;
  ip = '';
  id: number;
  detailToPrint = "";
  feeDilevery = 0;


  ngOnInit(): void {

    if (this.data.id === undefined) {
      this.cs.getId(this.data.commande_id).subscribe((d: any) => {
        this.id = d;
      });
    } else {
      this.id = this.data.id;
    }
    this.rs.all().subscribe((r: RestaurantInfo[]) => {
      this.restau = r[0];

    });
    if (this.listeOffres) {
      this.listeOffres.forEach(o => {
        o.requested_plats.forEach(p => {
          this.listePlatsOffres.push(p);
        });
      });
    }
  }

  close() {
    this.matdialogref.close();
  }

  getData() {
    for (let plat of this.listeplats) {
      this.detailToPrint += plat.nom + "\n";
      for (let costum of plat.customs) {
        for(let ing of costum.ingredients){
          this.detailToPrint += "  " + ing.nom + "\n";
        }
      }

    }
    if (this.listeOffres) {
      this.detailToPrint += "-------------LIST OFFRES----------- \n";

      for (let offre of this.listeOffres) {
        this.detailToPrint += offre.nom + "\n";
        for (let rp of offre.requested_plats) {
          this.detailToPrint += "    " + rp.nom + "\n";
          for(let cr of rp.customs){
            for(let ing of cr.ingredients){
              this.detailToPrint += "  " + ing.nom + "\n";
            }
          }
        }

      }
    }
  }


  print() {
    this.getData();
    this.printService.init()
      .setBold(true)
      .setJustification("center")
      .writeLine(this.restau.nom_restaurant)
      .setBold(false)
      .setJustification("center")
      .writeLine(this.restau.address)
      .setBold(false)
      .setJustification("center")
      .writeLine('Tel: ' + this.restau.tel)
      .writeLine('------------------------------------------')
      .writeLine('------------COORDONNEES CLIENT-------------')
      .setJustification("center")
      .writeLine(this.data.user.nom + ' ' + this.data.user.prenom)
      .setJustification("center")
      .writeLine(this.data.livraison_address)
      .setJustification("center")
      .writeLine(this.data.livraison ? this.data.livraison_address : 'Click and Collect')
      .setJustification("center")
      .writeLine(this.data.user.numero_de_telephone.toString())
      .writeLine('------------------------------------------')
      .setJustification("left")
      .writeLine('TICKET N: ' + this.id)
      .setJustification("left")
      .writeLine('Date: ' + this.data.created_at)
      .setJustification("left")
      .writeLine('Standard: ' + this.data.livraison ? 'Livraison' : 'Click and Collect')
      .setJustification("left")
      .writeLine('Client: ' + this.data.user.nom)
      .setJustification("center")
      .writeLine('Listes des articles: ')
      .setJustification("left")
      .writeLine(this.detailToPrint)
      .feed(2)
      .writeLine('TOTAL DES REMISES:    ' + '          0.00' )
      .writeLine('FRAIS DE LA LIVRAISON:' + '          ' +  this.restau.prixlivraison )
      .writeLine('TOTAL DU TICKET:      ' + '          ' +  this.feeDilevery )
      .setBold(true)
      .writeLine('A PAYER:              ' + '          ' + this.data.prix_total)
      .feed(6)
      .cut('full')
      .flush();
  }
}
